import React, {useState,useEffect} from "react";
import "./index.css";
import { useHistory,useLocation} from "react-router-dom";
import {MenuOutlined,CloseOutlined} from '@ant-design/icons';
import store from "../../story/story";
import { Input, Space,Menu,theme,Drawer} from "antd";
import { Helmet } from 'react-helmet';
const items = [
  {
    label: '首页',
    key: '/',
  },
  {
    label: '产品中心',
    key: '/product/',
	children: [
		{label: '信易盾·SCA',key: '/sca/'},
		{label: '信易盾·SAST',key: '/sast/'},
		{label: '信易盾·IAST',key: '/iast/'}
	]
  },
  {
    label: '灵信动态',
    key: '/trends/',
  },{
    label: '公司介绍',
    key: '/about/',
  },
  // {
  //   label: '技术支持',
  //   key: '/hp/',
  // 	children: [
  // 		{label: '使用手册',key: '/toc/'}
  // 	]
  // }
];

const menuStyle = {
	height: '70px',
	lineHeight: '70px',
	borderBottom: 'none',
	fontSize: '16px',
	fontFamily: 'DingTalkJinBuTi',
	paddingLeft:'37px',
	paddingRight:'37px',
	letterSpacing:'1px',
	background:'rgba(255,255,255,0.0)'
};

const menuStylePhone = {
	fontSize: '16px',
	fontFamily: 'DingTalkJinBuTi',
};

const App = () => {
	const history = useHistory();
	const location = useLocation();
	var pagename = location.pathname;
	console.log(pagename)
	const [current, setCurrent] = useState(pagename);
	const [isHeaderTransparent, setHeaderTransparent] = useState(true);
	// 路由跳转
	const onClick = (e) => {
		window.location.href = e.key;
		setCurrent(e.key);
		window.scrollTo(0, 0);
	};
	// 无限菜单
	const renderSubMenu = (subMenu) => {
		if(subMenu.children && subMenu.children.length > 0){
			return (
				<Menu.SubMenu key={subMenu.key} title={subMenu.label} style={menuStyle} theme="dark">
					{subMenu.children.map((child) => renderSubMenu(child))}
				</Menu.SubMenu>
			);
		} else {
			return <Menu.Item key={subMenu.key} style={menuStyle} theme="dark">
				{subMenu.label}
			</Menu.Item>
		}
	}
	// 移动端菜单
	const renderSubMenuPhone = (subMenu) => {
		if(subMenu.children && subMenu.children.length > 0){
			return (
				<Menu.SubMenu key={subMenu.key} title={subMenu.label} style={menuStylePhone} theme="dark">
					{subMenu.children.map((child) => renderSubMenu(child))}
				</Menu.SubMenu>
			);
		} else {
			return <Menu.Item key={subMenu.key} style={menuStylePhone} theme="dark">
				{subMenu.label}
			</Menu.Item>
		}
	}
	// 判断head透明
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 70) {
			// 当页面向下滚动超过100像素时，使页眉透明
				setHeaderTransparent(false);
			} else {
			// 否则使页眉不透明
				setHeaderTransparent(true);
			}
		};
		window.addEventListener('scroll', handleScroll);
		const unlisten = history.listen((location, action) => {
			if(location.pathname.length > 1){
				setCurrent(location.pathname.replace(/\//g, ''));
			}else{
				setCurrent(location.pathname);
			}
			
		});
		
		return () => {
		// 清除滚动事件监听器
			window.removeEventListener('scroll', handleScroll);
			unlisten();
		};
	}, [history]);
	const headerClassName = isHeaderTransparent ? 'header-transparent' : 'header-solid';
	
	// 移动端菜单
	const [isDrawerVisible, setDrawerVisible] = useState(false);
	const showDrawer = () => {
		if(isDrawerVisible == true){
			setDrawerVisible(false);
		}else{
			setDrawerVisible(true);
		}
	};

	const closeDrawer = () => {
		setDrawerVisible(false);
	};

	const MyDrawer = ({ visible, onClose }) => {
	  return (
	    <Drawer
	    	 className="header-menus"
	    	placement="top"
	    	height="auto"
	    	closable={false}
	    	visible={visible}
	    	closeIcon={true}
	    	onClose={onClose}
	    	style={{ background: '#000c17' }}
	    	>
	    		<Menu onClick={onClick} selectedKeys={[current]} mode="inline" style={menuStylePhone} theme="dark">
	    			{items.map((menuItem) => renderSubMenuPhone(menuItem))}
	    		</Menu>
	    </Drawer>
	  );
	};
	return(
		<>
			<div className={headerClassName}>
				<div className="header-wrapper">
					<a href="/" title="信易盾-开源软件管理平台" rel="home" class="logo">
						<img class="header-logo" src="https://sca.lasun.com.cn//img/logo.png" 
						alt="信易盾-开源软件管理平台"/>
					</a>
					<div className="header-menu">
						<Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" style={menuStyle} theme="dark"> 
							{items.map((menuItem) => renderSubMenu(menuItem))}
						</Menu>
					</div>
				</div>
			</div>
			<div className="header-phone">
				<Helmet>
					<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
				</Helmet>
				<div className="header-wrapper">
					<div class="header-logos">
						<a href="/" title="信易盾-开源软件管理平台" rel="home" class="logo">
							<img class="header-logo" src="https://sca.lasun.com.cn//img/logo.png" 
							alt="信易盾-开源软件管理平台"/>
						</a>
					</div>
					<div className="header-menu" onClick={showDrawer}>
						{isDrawerVisible ? <CloseOutlined/> : <MenuOutlined/>}
					</div>
					<MyDrawer visible={isDrawerVisible} onClose={closeDrawer} />
				</div>
				
			</div>
			
		</>
	);
};
export default App;
