import React, { useState,useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Input, Space,Menu,theme,Row,Col,Divider,Tooltip,Card,Image,Carousel} from "antd";
import {DownloadOutlined,RobotOutlined,CheckOutlined,EnvironmentOutlined
} from '@ant-design/icons';
import store from "../../../story/story";
import "./index.css";
import { Helmet } from 'react-helmet';

function Trends(){
	var hm = document.createElement("script");
	hm.src = "https://hm.baidu.com/hm.js?71b99c9d8eee3ed8bc4102a37ee8d3b5";
	var sr = document.getElementsByTagName("script")[0]; 
	sr.parentNode.insertBefore(hm, sr);
	return (
		<div className="trendsView">
			<Helmet>
				<title>SCA · 信易盾-开发安全全栈解决方案</title>
				<meta name="description" content="信易盾是一款Sca工具，包括信易盾客户端、信易盾Saas云服务、信易盾私有化部署和企业定制化附加服务"/>
				<meta name="keywords" content="安全左移, 研发安全, 开源安全, 漏洞扫描, DevSecOps, 敏捷安全, 软件成分分析sca, 
				银行sca, 保险sca, 证券sca, 开源治理, 金融sca" />
				<meta name="360-site-verification" content="dbbd44dd1935b60559ea7c6905b89cae" />
			</Helmet>
			<div className="trendsVideo">
				<img src="https://sca.lasun.com.cn//img/trendbg.png" className="trendsBg"/>
			</div>
			<div className="trendsCon1">
				<div className="trendsTitle">
					新闻动态
				</div>
				<div className="trend1Table">
					<div className="trend1Item">
						<div className="trend1Left">
							<div className="newsTitle">信易盾SaaS版本上线</div>
							<div className="newsCon">信易盾SaaS版本基于信易盾SCA版本推出，实现了下载即用、灵活租赁等使用模式，更方便中小企业及个人开发者使用，助力开发者轻松实现开发安全。</div>
						</div>
						<div className="trend1Right">
							2023年10月
						</div>
					</div>
					<div className="trend1Item">
						<div className="trend1Left">
							<div className="newsTitle">
								与多家城商银行达成合作
							</div>
							<div className="newsCon">
								通过实践经验的积累及产品功能的迭代优化，信易盾在行业内取得了众多客户的良好评价与信任，截至2023年7月，信易盾已与华北及东北地区多家银行达成了合作关系。
							</div>
						</div>
						<div className="trend1Right">
							2023年7月
						</div>
					</div>
					<div className="trend1Item">
						<div className="trend1Left">
							<div className="newsTitle">
								与北京某银行确立合作关系
							</div>
							<div className="newsCon">
								通过前期的技术交流及合作，信易盾与客户之间建立了的充分的沟通交流，取得了客户的深度信任，与客户确立了合作关系，为客户提供持续的开源软件安全治理服务。
							</div>
						</div>
						<div className="trend1Right">
							2023年2月
						</div>
					</div>
					<div className="trend1Item">
						<div className="trend1Left">
							<div className="newsTitle">
								参与北京某银行的技术交流
							</div>
							<div className="newsCon">
								应客户邀请，信易盾团队参与北京某银行针对开源软件治理的技术交流，并参与POC测试，充分展示了信易盾SCA产品在开源软件治理中发挥的技术支撑作用。
							</div>
						</div>
						<div className="trend1Right">
							2022年7月
						</div>
					</div>
					<div className="trend1Item">
						<div className="trend1Left">
							<div className="newsTitle">
								信易盾SCA产品上线
							</div>
							<div className="newsCon">
								经过团队的潜心研发打造，信易盾SCA产品于2022年6月正式上线发布，包括组件成分分析、漏洞风险识别、合规风险管控等多项功能，助力企业实现开源软件安全管理，保护代码安全。
							</div>
						</div>
						<div className="trend1Right">
							2022年6月
						</div>
					</div>
					<div className="trend1Item">
						<div className="trend1Left">
							<div className="newsTitle">
								灵信互动大连研发中心正式成立
							</div>
							<div className="newsCon">
								为推动灵信互动核心技术产品研发，灵信互动在大连设立产品研发中心，承担公司核心产品的研发及迭代，开拓灵信互动在全国范围内的技术及人才布局。
							</div>
						</div>
						<div className="trend1RightEnd">
							2022年1月
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Trends;
