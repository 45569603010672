import React, { useState,useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Input, Space,Menu,theme,Row,Col,Divider,Tooltip,Card,Image,Modal} from "antd";
import {DownloadOutlined,RobotOutlined,CheckOutlined
} from '@ant-design/icons';
import store from "../../../story/story";
import "./index.css";
import { Helmet } from 'react-helmet';

function Last() {
	var hm = document.createElement("script");
	hm.src = "https://hm.baidu.com/hm.js?71b99c9d8eee3ed8bc4102a37ee8d3b5";
	var sr = document.getElementsByTagName("script")[0]; 
	sr.parentNode.insertBefore(hm, sr);
	
	const [open, setOpen] = useState(false);
	
	const showModal = () => {
	  setOpen(true);
	};
	
	const handleCancel = () => {
	  setOpen(false);
	};
	
	function isMobileDevice() {
	  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	}
	useEffect(() => {
	    const myVideo3 = document.getElementById('myVideo3');
	    if(isMobileDevice()){
	    		
	    }else{
			if (myVideo3) {
			myVideo3.play();
			}
	    }
	}, []);
	
	return (
		<div className="iastView">
			<Helmet>
				<title>IAST · 信易盾-开发安全全栈解决方案</title>
				<meta name="description" content="信易盾是一款Sca工具，包括信易盾客户端、信易盾Saas云服务、信易盾私有化部署和企业定制化附加服务"/>
				<meta name="keywords" content="安全左移, 研发安全, 开源安全, 漏洞扫描, DevSecOps, 敏捷安全, 软件成分分析sca, 
				银行sca, 保险sca, 证券sca, 开源治理, 金融sca" />
				<meta name="360-site-verification" content="dbbd44dd1935b60559ea7c6905b89cae" />
			</Helmet>
			<div className="iastVideo">
				<video id="myVideo3" width="100%" height="auto" muted loop >
					<source  src="https://sca.lasun.com.cn//img/iastV1.mp4" type="video/mp4" />
				</video>
			</div>
			<div className="iastContent">
				<div className="iastTitle">
					信易盾 · IAST
				</div>
				<div className="iastTitle2">
					交互式安全测试
				</div>
				<div className="iastTitle3">
					<Row gutter={20}>
						<Col span={8}>
							<div className="iastBtn">
								检测全覆盖
							</div>
						</Col>
						<Col span={8}>
							<div className="iastBtn">
								业务零打扰
							</div>
						</Col>
						<Col span={8}>
							<div className="iastBtn">
								大规模自动化部署
							</div>
						</Col>
					</Row>
				</div>
				<div className="iastTitleBtn" onClick={showModal}>
					申请试用
				</div>
				{
					// web
					<div className="iastCon2">
					<div className="iast2Card1">
						<Row gutter={20}>
							<Col span={12}>
								<div className="iast2cImgBg">
									<img  className="iast2cImg" src="https://sca.lasun.com.cn//img/iast2.png"/>
								</div>
							</Col>
							<Col span={12}>
								<div className="iast2cView">
									<div className="iast2cText1">
										检测全覆盖
									</div>
									<div className="iast2cText2">
										支持微服务架构下跨服务漏洞场景检测，应用安全漏洞类型覆盖率高，所有核心规则均开放且可自定义。
									</div>
								</div>
							</Col>
						</Row>
					</div>
					<div className="iast2Card1">
						<Row gutter={20}>
							<Col span={12}>
								<div className="iast2cView">
									<div className="iast2cText1">
										业务零打扰
									</div>
									<div className="iast2cText2">
										运行过程无感知、不产生任何脏数据。在全球装机量超过20万台，十万级部署、万级并发，仍能无感运行。
									</div>
								</div>
							</Col>
							<Col span={12}>
								<div className="iast2cImgBg">
									<img  className="iast2cImg" src="https://sca.lasun.com.cn//img/iast3.png"/>
								</div>
							</Col>
						</Row>
					</div>
					<div className="iast2Card1">
						<Row gutter={20}>
							<Col span={12}>
								<div className="iast2cImgBg">
									<img  className="iast2cImg" src="https://sca.lasun.com.cn//img/iast1.png"/>
								</div>
							</Col>
							<Col span={12}>
								<div className="iast2cView">
									<div className="iast2cText1">
										大规模自动化部署
									</div>
									<div className="iast2cText2">
										支持云原生、Docker、K8S等多种Agent部署方式，支持Agent热部署，无需重启应用。
									</div>
								</div>
								
							</Col>
						</Row>
					</div>
					
				</div>
				}
				
				{
					// 移动端
						<div className="iastCon2Phone">
						<div className="iast2Card1">
							<div className="iast2cView">
								<div className="iast2cText1">
									检测全覆盖
								</div>
								<div className="iast2cText2">
									支持微服务架构下跨服务漏洞场景检测，应用安全漏洞类型覆盖率高，所有核心规则均开放且可自定义。
								</div>
							</div>
						</div>
						<div className="iast2Card1">
							<div className="iast2cView">
								<div className="iast2cText1">
									业务零打扰
								</div>
								<div className="iast2cText2">
									运行过程无感知、不产生任何脏数据。在全球装机量超过20万台，十万级部署、万级并发，仍能无感运行。
								</div>
							</div>
						</div>
						<div className="iast2Card1">
							<div className="iast2cView">
								<div className="iast2cText1">
									大规模自动化部署
								</div>
								<div className="iast2cText2">
									支持云原生、Docker、K8S等多种Agent部署方式，支持Agent热部署，无需重启应用。
								</div>
							</div>
						</div>
						
					</div>
				}
				
				<div className="iastCon3">
					<Divider plain style={{background:'rgba(164, 205, 255, 0.3)'}}>
					</Divider>
					<div className="iastCardCon">
						<img class="iastCardImg" src="https://sca.lasun.com.cn//img/iastys.png"
						alt="信易盾-开源软件管理平台"/>
					</div>
					<div className="iastc3Title">
						产品优势
					</div>
					<div className="iast3Div">
						<div className="iast3Div1">
							<div className="iast3cTitle">
								检测能力与技术指标
							</div>
							<div className="iast3cText1">
								<CheckOutlined className="iastIconr"/>支持Java、PHP、PytHon、Go等多种主流语言
							</div>
							<div className="iast3cText2">
								<CheckOutlined className="iastIconr"/>支持Apache、Nginx、Tomcat等主流容器
							</div>
							<div className="iast3cText2">
								<CheckOutlined className="iastIconr"/>支持Django、Laravel、Spring Framework等Web应用框架
							</div>
							<div className="iast3cText2">
								<CheckOutlined className="iastIconr"/>覆盖CWE、CVE、CNNVD等多个漏洞数据及检测标准
							</div>
						</div>
						
						<div className="iast3Div2">
							<div className="iast3cTitle">
								可根据业务需求灵活部署
							</div>
							<div className="iast3cText1">
								<CheckOutlined className="iastIconr"/>可根据需要选择代理模式、VPN、流量镜像、插桩模式等
							</div>
							<div className="iast3cText2">
								<CheckOutlined className="iastIconr"/>可根据业务环境选择脚本部署、冷部署多种方式
							</div>
							<div className="iast3cText2">
								<CheckOutlined className="iastIconr"/>满足高并发模式、获取请求、代码控制流等多种应用场景
							</div>
						</div>
					</div>
					<div className="iast3Div">
						<div className="iast3Div3">
							<div className="iast3cTitle">
								企业级产品，安全有保障
							</div>
							<div className="iast3cText1">
								<CheckOutlined className="iastIconr"/>支持对检出信息的加密存储及传输
							</div>
							<div className="iast3cText2">
								<CheckOutlined className="iastIconr"/>提供SAAS/本地化服务，可随时卸载
							</div>
							<div className="iast3cText2">
								<CheckOutlined className="iastIconr"/>可根据权限机制控制用户获取信息细粒度
							</div>
							<div className="iast3cText2">
								<CheckOutlined className="iastIconr"/>支持系统关键操作记录留痕及调取
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal open={open} onCancel={handleCancel} footer={[]} width="500px">
				<div className="modelView">
					<div className="modelTitle">
						WeChat
					</div>
					<div className="modelTitle2">
						官方公众号
					</div>
					<div className="modelIcon3">
						<div className="modelImgV1">
							<img class="modelqrimgs" src="https://sca.lasun.com.cn//img/qrcode.png" />
							<div class="modelText">
								灵信互动
							</div>
						</div>
						<div className="modelImgV1">
							<img class="modelqrimgs" src="https://sca.lasun.com.cn//img/qrcode2.jpg" />
							<div class="modelText">
								产品服务
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
}

export default Last;
