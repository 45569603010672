import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import home from "./home/index";
import sca from "./sca/index";
import sast from "./sast/index";
import iast from "./iast/index";
import trends from "./trends/index";
import about from "./about/index";
import toc from "./toc/index";
import Delatils from "./details";

export default class index extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path="/" exact component={home}></Route>
		  <Route path="/sca" exact component={sca}></Route>
		  <Route path="/sast" exact component={sast}></Route>
		  <Route path="/iast" exact component={iast}></Route>
		  <Route path="/trends" exact component={trends}></Route>
		  <Route path="/about" exact component={about}></Route>
          <Route path="/details" component={Delatils}></Route>
		  <Route path="/toc" component={toc}></Route>
        </Switch>
      </div>
    );
  }
}
