import React, { useState,useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Input, Space,Menu,theme,Row,Col,Divider,Tooltip,Card,Image,Modal} from "antd";
import {DownloadOutlined,RobotOutlined
} from '@ant-design/icons';
import store from "../../../story/story";
import "./index.css";
import { Helmet } from 'react-helmet';

function Sast(){
	var hm = document.createElement("script");
	hm.src = "https://hm.baidu.com/hm.js?71b99c9d8eee3ed8bc4102a37ee8d3b5";
	var sr = document.getElementsByTagName("script")[0]; 
	sr.parentNode.insertBefore(hm, sr);
	const [open, setOpen] = useState(false);
	
	const showModal = () => {
	  setOpen(true);
	};
	
	const handleCancel = () => {
	  setOpen(false);
	};
	return (
		<div className="sastView">
			<Helmet>
				<title>SAST · 信易盾-开发安全全栈解决方案</title>
				<meta name="description" content="信易盾是一款Sca工具，包括信易盾客户端、信易盾Saas云服务、信易盾私有化部署和企业定制化附加服务"/>
				<meta name="keywords" content="安全左移, 研发安全, 开源安全, 漏洞扫描, DevSecOps, 敏捷安全, 软件成分分析sca, 
				银行sca, 保险sca, 证券sca, 开源治理, 金融sca" />
				<meta name="360-site-verification" content="dbbd44dd1935b60559ea7c6905b89cae" />
			</Helmet>
			<div className="sastVideo">
				<img src="https://sca.lasun.com.cn//img/sastbg.jpg" className="sastVideoImg"/>
			</div>
			<div className="sastContent">
				<div className="sastTitle">
					信易盾 · SAST
				</div>
				<div className="sastTitle2">
					静态代码分析
				</div>
				<div className="sastTitle3">
					<Row gutter={20}>
						<Col span={8}>
							<div className="sastBtn">
								编码安全检测
							</div>
						</Col>
						<Col span={8}>
							<div className="sastBtn">
								开发效率提升
							</div>
						</Col>
						<Col span={8}>
							<div className="sastBtn">
								DevSecOps方案
							</div>
						</Col>
					</Row>
				</div>
				<div className="sastTitleBtn" onClick={showModal}>
					开始试用
				</div>
				<div className="sastCon2">
					<div className="sast2Card1">
						<Row gutter={20}>
							<Col span={12}>
								<div className="sast2cImgBg">
									<img  className="sast2cImg" src="https://sca.lasun.com.cn//img/sast2.png"/>
								</div>
							</Col>
							<Col span={12}>
								<div className="sast2cView">
									<div className="sast2cText1">
										编码安全检测
									</div>
									<div className="sast2cText2">
										通过源代码或二进制文件分析检测，查找编码中存在的漏洞，帮助开发人员在开发初期阶段就能够发现并解决安全问题。
									</div>
								</div>
							</Col>
						</Row>
					</div>
					<div className="sast2Card1">
						<Row gutter={20}>
							<Col span={12}>
								<div className="sast2cView">
									<div className="sast2cText1">
										项目流管理
									</div>
									<div className="sast2cText2">
										简化项目计划、报告管理、规则配置等多种功能的管理，便于企业同时管理多个代码库。
									</div>
								</div>
							</Col>
							<Col span={12}>
								<div className="sast2cImgBg">
									<img  className="sast2cImg" src="https://sca.lasun.com.cn//img/sast3.png"/>
								</div>
							</Col>
						</Row>
					</div>
					<div className="sast2Card1">
						<Row gutter={20}>
							<Col span={12}>
								<div className="sast2cImgBg">
									<img  className="sast2cImg" src="https://sca.lasun.com.cn//img/sast1.png"/>
								</div>
							</Col>
							<Col span={12}>
								<div className="sast2cView">
									<div className="sast2cText1">
										定制仪表盘管理
									</div>
									<div className="sast2cText2">
										全量化管理代码库中的所有检测数据、安全指标及配置信息，实时掌控风险概况及安全管理
									</div>
								</div>
								
							</Col>
						</Row>
					</div>
					
				</div>
				{
					// 移动端
				}
				<div className="sastCon2Phone">
					<div className="sast2Card1">
						<div className="sast2cView">
							<div className="sast2cText1">
								编码安全检测
							</div>
							<div className="sast2cText2">
								通过源代码或二进制文件分析检测，查找编码中存在的漏洞，帮助开发人员在开发初期阶段就能够发现并解决安全问题。
							</div>
						</div>
					</div>
					<div className="sast2Card1">
						<div className="sast2cView">
							<div className="sast2cText1">
								项目流管理
							</div>
							<div className="sast2cText2">
								简化项目计划、报告管理、规则配置等多种功能的管理，便于企业同时管理多个代码库。
							</div>
						</div>
					</div>
					<div className="sast2Card1">
						<div className="sast2cView">
							<div className="sast2cText1">
								定制仪表盘管理
							</div>
							<div className="sast2cText2">
								全量化管理代码库中的所有检测数据、安全指标及配置信息，实时掌控风险概况及安全管理
							</div>
						</div>
					</div>
					
				</div>
				<div className="sastCon3">
					<Divider plain style={{background:'rgba(164, 205, 255, 0.3)'}}>
					</Divider>
					<div className="sastCardCon">
						<img class="sastCardImg" src="https://sca.lasun.com.cn//img/sastys.png"
						alt="信易盾-开源软件管理平台"/>
					</div>
					<div className="sastc3Title">
						产品优势
					</div>
					<div className="sast3Div">
						<div className="sast3Div1">
							<div className="sast3cTitle">
								识别代码风险
							</div>
							<div className="sast3cText1">
								强大的风险数据库&深度的风险检测，为客户识别组件中的每一个漏洞
							</div>
							<div className="sast3cImg">
								<img class="sastImgS" src="https://sca.lasun.com.cn//img/sastfx.gif" alt="信易盾-开源软件管理平台"/>
							</div>
						</div>
						
						<div className="sast3Div2">
							<div className="sast3cTitle">
								应用场景多样
							</div>
							<div className="sast3cText1">
								支持对WEB应用程序、APP等多种业务环境进行检测，支持帮助识别软件安全性、质量和可靠性等多种问题类型。
							</div>
							<div className="sast3cImg">
								<img class="sastImgS" src="https://sca.lasun.com.cn//img/sastdy.gif" alt="信易盾-开源软件管理平台"/>
							</div>
						</div>
					</div>
					<div className="sast3Div">
						<div className="sast3Div3">
							<div className="sast3cTitle">
								集成DevSecOps方案
							</div>
							<div className="sast3cText1">
								支持任意部署到不同项目、不同团队中，与企业DevOps及DevSecOps流程融合。
							</div>
							<div className="sast3cImg">
								<img class="sastImgS"  src="https://sca.lasun.com.cn//img/sastfa.gif" alt="信易盾-开源软件管理平台"/>
							</div>
						</div>
						<div className="sast3Div4">
							<div className="sast3cTitle">
								为开发人员量身打造
							</div>
							<div className="sast3cText1">
								不需要用户界面，易于使用，可无缝接入开发流程中，提高开发人员的生产力及安全意识。
							</div>
							<div className="sast3cImg">
								<img class="sastImgS" src="https://sca.lasun.com.cn//img/sastdz.gif" alt="信易盾-开源软件管理平台"/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal open={open} onCancel={handleCancel} footer={[]} width="500px">
				<div className="modelView">
					<div className="modelTitle">
						WeChat
					</div>
					<div className="modelTitle2">
						官方公众号
					</div>
					<div className="modelIcon3">
						<div className="modelImgV1">
							<img class="modelqrimgs" src="https://sca.lasun.com.cn//img/qrcode.png" />
							<div class="modelText">
								灵信互动
							</div>
						</div>
						<div className="modelImgV1">
							<img class="modelqrimgs" src="https://sca.lasun.com.cn//img/qrcode2.jpg" />
							<div class="modelText">
								产品服务
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
}
export default Sast;
