import React, { useState,useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Input, Space,Menu,theme,Row,Col,Divider,Tooltip,Card,Image,Carousel} from "antd";
import {DownloadOutlined,RobotOutlined,CheckOutlined,EnvironmentOutlined
} from '@ant-design/icons';
import store from "../../../story/story";
import "./index.css";
import { Helmet } from 'react-helmet';
function About(){
	var hm = document.createElement("script");
	hm.src = "https://hm.baidu.com/hm.js?71b99c9d8eee3ed8bc4102a37ee8d3b5";
	var sr = document.getElementsByTagName("script")[0]; 
	sr.parentNode.insertBefore(hm, sr);
	return (
		<div className="aboutView">
			<Helmet>
				<title>关于我们 · 信易盾-开发安全全栈解决方案</title>
				<meta name="description" content="信易盾是一款Sca工具，包括信易盾客户端、信易盾Saas云服务、信易盾私有化部署和企业定制化附加服务"/>
				<meta name="keywords" content="安全左移, 研发安全, 开源安全, 漏洞扫描, DevSecOps, 敏捷安全, 软件成分分析sca, 
				银行sca, 保险sca, 证券sca, 开源治理, 金融sca" />
				<meta name="360-site-verification" content="dbbd44dd1935b60559ea7c6905b89cae" />
			</Helmet>
			<div className="aboutVideo">
				<img src="https://sca.lasun.com.cn//img/aboutbg.png" className="aboutBg"/>
			</div>
			<div className="aboutContent">
				<div className="aboutTitle">
					以开发安全为核心，为企业数字化转型的安全问题保驾护航
				</div>
				<div className="aboutCon2">
					<div className="about2Card1">
						<Row gutter={20}>
							<Col span={12}>
								<div className="about2cView">
									<div className="about2cText1">
										关于灵信互动
									</div>
									<div className="about2cText2">
										灵信互动（LASUN）成立于2005年，总部位于北京。十余年来专注软件开发安全，自主研发安全产品，积极创新开发安全服务模式，为企业数字化转型的安全问题保驾护航。现拥有软件成分分析工具SCA、静态代码应用安全测试工具SAST、交互式应用安全测试工具IAST等多种开发安全产品，能够集成DevSecOps模式，持续守护软件开发安全。目前，灵信互动已与多家金融、通信、互联网客户达成深度合作，为客户打造开发安全解决方案，筑牢守护信息安全的坚实屏障。
									</div>
									<div className="about2cText3">
										面对日益复杂的网络安全威胁，灵信互动主动肩负起捍卫开发安全的重任，以实现国产化替代为目标，坚持自主创新，积极推动开发安全产业的发展，助力各行各业响应安全挑战与时代变化，携手建设软件供应链安全生态。
									</div>
								</div>
							</Col>
							<Col span={12}>
								<div className="about2cImgBg">
									<img className="about2cImg" src="https://sca.lasun.com.cn//img/aboutgy.png"/>
								</div>
							</Col>
						</Row>
					</div>
				</div>
				<div className="aboutCon3">
					<Divider plain style={{background:'rgba(164, 205, 255, 0.3)'}}>
					</Divider>
					<div className="aboutCardCon">
						<img class="aboutCardImg"  src="https://sca.lasun.com.cn//img/aboutus.png"
						alt="信易盾-开源软件管理平台"/>
					</div>
					<div className="aboutc3Title">
						公司资质
					</div>
					<div className="aboutWapper">
						<div class="aboutWleft">
							<Carousel dotPosition="left" autoplay>
								<div className="about-ld">
									<img src="https://sca.lasun.com.cn/wp-content/uploads/2023/05/9000.png"/>
								</div>
								<div className="about-ld">
									<img src="https://sca.lasun.com.cn/wp-content/uploads/2023/05/20000中文版-scaled.jpg"/>
								</div>
								<div className="about-ld">
									<img src="https://sca.lasun.com.cn/wp-content/uploads/2023/05/27001中文版-scaled.jpg"/>
								</div>
								<div className="about-ld">
									<img src="https://sca.lasun.com.cn/wp-content/uploads/2023/05/CCRC安全集成.jpg"/>
								</div>
							</Carousel>
						</div>
						<div class="aboutWright">
							<Carousel dotPosition="bottom" autoplay>
								<div className="about-rd">
									<img src="https://sca.lasun.com.cn/wp-content/uploads/2023/05/CMMI中文-scaled.jpg"/>
								</div>
								<div className="about-rd">
									<img src="https://sca.lasun.com.cn/wp-content/uploads/2023/05/信创证书_202210251-scaled.jpg"/>
								</div>
								<div className="about-rd">
									<img src="https://sca.lasun.com.cn/wp-content/uploads/2023/05/中关村高新证书.jpg"/>
								</div>
								<div className="about-rd">
									<img src="https://sca.lasun.com.cn/wp-content/uploads/2023/05/国高新.jpg"/>
								</div>
							</Carousel>
						</div>
					</div>
				</div>
				
				<div className="aboutCon4">
					<Divider plain style={{background:'rgba(164, 205, 255, 0.3)'}}>
					</Divider>
					<div className="aboutc4Title">
						联系我们
					</div>
					<Row>
						<Col span={12}>
							<div className="aboutlxLeft">
								<div className="aboutlx1">
									<EnvironmentOutlined  className="aboutIcon"/>北京（总部）
								</div>
								<div className="aboutlx2">
									地址：北京市海淀区高梁桥斜街42号院1号楼3层
								</div>
								<div className="aboutlx3">
									邮箱：lei.sha@Iausn.com.cn
								</div>
							</div>
							
						</Col>
						<Col span={12}>
							<div  className="aboutlxRight">
								<div className="aboutlx1">
									<EnvironmentOutlined className="aboutIcon"/>大连(研发中心)
								</div>
								<div className="aboutlx2">
									地址：大连市甘井子区火炬路32号创业大厦19层
								</div>
								<div className="aboutlx3">
									邮箱：hui.wang@lasun.com.cn
								</div>
							</div>
						</Col>
					</Row>
				</div>
				{
					// 联系我们移动端
				}
				<div className="aboutCon4Phone">
					<Divider plain style={{background:'rgba(164, 205, 255, 0.3)'}}>
					</Divider>
					<div className="aboutc4Title">
						联系我们
					</div>
					<div className="aboutlxLeft">
						<div className="aboutlx1">
							<EnvironmentOutlined  className="aboutIcon"/>北京（总部）
						</div>
						<div className="aboutlx2">
							地址：北京市海淀区高梁桥斜街42号院1号楼3层
						</div>
						<div className="aboutlx3">
							邮箱：lei.sha@Iausn.com.cn
						</div>
					</div>
					<div  className="aboutlxRight">
						<div className="aboutlx1">
							<EnvironmentOutlined className="aboutIcon"/>大连(研发中心)
						</div>
						<div className="aboutlx2">
							地址：大连市甘井子区火炬路32号创业大厦19层
						</div>
						<div className="aboutlx3">
							邮箱：hui.wang@lasun.com.cn
						</div>
					</div>
				</div>
			</div>
			
		</div>
	);
}
export default About;
