import React, {useState,useEffect} from "react";
import "./index.css";
import { useHistory,useLocation} from "react-router-dom";
import store from "../../story/story";
import { Input, Space,Menu,theme, Row,Col,Tooltip,Modal} from "antd";
import {GithubOutlined,GitlabOutlined,WechatOutlined} from '@ant-design/icons';

const items = [
  {
    label: '首页',
    key: '/',
  },
  {
    label: '产品中心',
    key: '/product/',
	children: [
		{label: '信易盾·SCA',key: '/sca/'},
		{label: '信易盾·SAST',key: '/sast/'},
		{label: '信易盾·IAST',key: '/iast/'}
	]
  },
  {
    label: '灵信动态',
    key: '/trends/',
  },{
    label: '公司介绍',
    key: '/about/',
  },
  {
    label: '技术支持',
    key: '/hp/',
	children: [
		{label: '使用手册',key: '/toc/'}
	]
  }
  
];


const menuStyle = {
	height: '50px',
	lineHeight: '50px',
	borderBottom: 'none',
	margin:'auto',
	fontSize: '14px',
	fontFamily: 'DingTalkJinBuTi',
	letterSpacing:'1px',
	background:'rgba(255,255,255,0.0)'
};

const App = () => {
	const history = useHistory();
	const location = useLocation();
	var pagename = location.pathname;
	const [current, setCurrent] = useState(pagename);
	const [isHeaderTransparent, setHeaderTransparent] = useState(true);
	const [open, setOpen] = useState(false);
	// 路由跳转
	const onClick = (e) => {
		window.location.href = e.key;
		window.scrollTo(0, 0);
		setCurrent(e.key);
	};
	
	const showModal = () => {
		setOpen(true);
	};
	
	const handleCancel = () => {
	    setOpen(false);
	};
	const goopen = (url) => {
	  window.open(url);
	}
	const renderSubMenu = (subMenu) => {
		if(subMenu.children && subMenu.children.length > 0){
			return (
				<Menu.SubMenu key={subMenu.key} title={subMenu.label} style={menuStyle}>
					{subMenu.children.map((child) => renderSubMenu(child))}
				</Menu.SubMenu>
			);
		} else {
			return <Menu.Item key={subMenu.key} style={menuStyle}>
				{subMenu.label}
			</Menu.Item>
		}
	}
	
	// 判断head透明
	useEffect(() => {
		const unlisten = history.listen((location, action) => {
			if(location.pathname.length > 1){
				setCurrent(location.pathname.replace(/\//g, ''));
			}else{
				setCurrent(location.pathname);
			}
			
		});
		
		return () => {
			unlisten();
		};
	}, [history]);
	
	return(
		<>
			<div className="bottomBg">
				<div className="bottomView">
					<div className="bottomNav">
						<Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" theme="dark" style={menuStyle}>
							{items.map((menuItem) => renderSubMenu(menuItem))}
						</Menu>
					</div>
					<div className="bottomCon">
						<Row>
							<Col span={6}>
								<div className="bottomcIcon1">
									WeChat
								</div>
								<div className="bottomcIcon2">
									官方公众号
								</div>
								<div className="bottomcIcon3">
									<div className="bottomImgV1">
										<img class="bottomqrimgs" src="https://sca.lasun.com.cn//img/qrcode.png" />
										<div class="bottomqrText">
											灵信互动
										</div>
									</div>
									<div className="bottomImgV2">
										<img class="bottomqrimgs" src="https://sca.lasun.com.cn//img/qrcode2.jpg" />
										<div class="bottomqrText">
											产品服务
										</div>
									</div>
								</div>

							</Col>
							<Col span={6}>
								<div className="bottomcText1">
									E-mail
								</div>
								<div className="bottomcText2">
									联系邮箱：
								</div>
								<div className="bottomcText3">
									lei.sha@Iausn.com.cn
								</div>
								<div className="bottomcText4">
									hui.wang@lasun.com.cn
								</div>
							</Col>
							<Col span={6}>
								<div className="bottomcText1">
									Address
								</div>
								<div className="bottomcText2">
									公司地址：
								</div>
								<div className="bottomcText5">
									北京（总部）
								</div>
								<div className="bottomcText6">
									北京市海淀区高梁桥斜街42号院1号楼3层
								</div>
								<div className="bottomcText7">
									大连(研发中心)
								</div>
								<div className="bottomcText8">
									大连市甘井子区火炬路32号创业大厦19层
								</div>
							</Col>

							<Col span={6}>
								{
								// 	<div className="bottomOteher1">
								// 	关注即可获取
								// </div>
								// <div className="bottomOteher2">
								// 	最新漏洞资讯
								// </div>
								// <div className="bottomOteher3">
								// 	产品使用手册
								// </div>
								}
								<div className="bottomOteher4">
									<Row gutter={20}>
										<Col span={8}>
											<Tooltip title="开源上线中 敬请期待">
												<GithubOutlined className="bottomOthBtn"/>
											</Tooltip>
										</Col>
										<Col span={8}>
											<Tooltip title="开源上线中 敬请期待" onClick={() => { goopen('https://gitee.com/lasun_opensource')}}>
												<img src="https://sca.lasun.com.cn//img/gitee.svg" className="bottomOthBtn"/>
											</Tooltip>
										</Col>
										<Col span={8}>
											<Tooltip title="联系我们">
												<div onClick={showModal}>
													<WechatOutlined className="bottomOthBtn"/>
												</div>
											</Tooltip>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					</div>
					<div className="bottomConPhone">
						<div className="bottomcText2">
							联系邮箱：
						</div>
						<div className="bottomcText3">
							lei.sha@Iausn.com.cn
						</div>
						<div className="bottomcText4">
							hui.wang@lasun.com.cn
						</div>
						<div className="bottomcText2">
							公司地址：
						</div>
						<div className="bottomcText5">
							北京（总部）
						</div>
						<div className="bottomcText6">
							北京市海淀区高梁桥斜街42号院1号楼3层
						</div>
						<div className="bottomcText7">
							大连(研发中心)
						</div>
						<div className="bottomcText8">
							大连市甘井子区火炬路32号创业大厦19层
						</div>
						<div className="bottomOteher4">
							<Row gutter={20}>
								<Col span={8}>
									<Tooltip title="开源上线中 敬请期待">
										<GithubOutlined className="bottomOthBtn"/>
									</Tooltip>
								</Col>
								<Col span={8}>
									<Tooltip title="开源上线中 敬请期待" onClick={() => { goopen('https://gitee.com/lasun_opensource')}}>
										<img src="https://sca.lasun.com.cn//img/gitee.svg" className="bottomOthBtn"/>
									</Tooltip>
								</Col>
								<Col span={8}>
									<div onClick={showModal}>
										<WechatOutlined className="bottomOthBtn"/>
									</div>
								</Col>
							</Row>
						</div>
						<div className="bottomcIcon3">
							<img class="bottomqrimgs" src="https://sca.lasun.com.cn//img/qrcode.png" />
							<div class="bottomqrText">
								灵信互动
							</div>
							<img class="bottomqrimgs" src="https://sca.lasun.com.cn//img/qrcode2.jpg" />
							<div class="bottomqrText">
								产品服务
							</div>
						</div>
					</div>
					<div className="bottomIcp">
						ICP备案号:京ICP备13001554号©北京灵信互动信息技术有限公司
					</div>
				</div>
			</div>
			<Modal open={open} onCancel={handleCancel} footer={[]} width="500px">
				<div className="modelView">
					<div className="modelTitle">
						WeChat
					</div>
					<div className="modelTitle2">
						官方公众号
					</div>
					<div className="modelIcon3">
						<div className="modelImgV1">
							<img class="modelqrimgs" src="https://sca.lasun.com.cn//img/qrcode.png" />
							<div class="modelText">
								灵信互动
							</div>
						</div>
						<div className="modelImgV1">
							<img class="modelqrimgs" src="https://sca.lasun.com.cn//img/qrcode2.jpg" />
							<div class="modelText">
								产品服务
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};
export default App;
