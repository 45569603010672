import React, { useState,useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Input, Space,Menu,theme,Row,Col,Divider,Tooltip,Card,Image,Carousel} from "antd";
import {DownloadOutlined,RobotOutlined,CheckOutlined,EnvironmentOutlined
} from '@ant-design/icons';
import store from "../../../story/story";
import "./index.css";
import { Helmet } from 'react-helmet';

function Toc(){
	return (
		<div className="tocView">
			<iframe className="tociframe" src="https://sca.lasun.com.cn/book/tocs.html"></iframe>
		</div>
	);
}
export default Toc;
