import "./reset.css";
import Header from "./component/header/index";
import Content from "./component/content/index";
import Bottom from "./component/bottom/index";
function App() {
  return (
	<div className="App">
		<Header></Header>
		<Content></Content>
		<Bottom></Bottom>
	</div>
  );
}

export default App;
