import React, { useState,useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Input, Space,Menu,theme,Row,Col,Divider,Tooltip,Card,Image} from "antd";
import {DownloadOutlined,RobotOutlined,ApiOutlined
} from '@ant-design/icons';
import store from "../../../story/story";
import "./index.css";
import { Helmet } from 'react-helmet';

function Sca(){
	var hm = document.createElement("script");
	hm.src = "https://hm.baidu.com/hm.js?71b99c9d8eee3ed8bc4102a37ee8d3b5";
	var sr = document.getElementsByTagName("script")[0]; 
	sr.parentNode.insertBefore(hm, sr);
	
	const goopen = (url) =>  {
		window.open(url)
	}
	
	function isMobileDevice() {
	  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	}
	useEffect(() => {
	    const myVideo4 = document.getElementById('myVideo4');
		const myVideo5 = document.getElementById('myVideo5');
	    if(isMobileDevice()){
	    		
	    }else{
			if (myVideo4) {
			myVideo4.play();
			}
			if (myVideo5) {
			myVideo4.play();
			}
	    }
	}, []);
	
	return (
		<div className="scaView">
			<Helmet>
				<title>SCA · 信易盾-开发安全全栈解决方案</title>
				<meta name="description" content="信易盾是一款Sca工具，包括信易盾客户端、信易盾Saas云服务、信易盾私有化部署和企业定制化附加服务"/>
				<meta name="keywords" content="安全左移, 研发安全, 开源安全, 漏洞扫描, DevSecOps, 敏捷安全, 软件成分分析sca, 
				银行sca, 保险sca, 证券sca, 开源治理, 金融sca" />
				<meta name="360-site-verification" content="dbbd44dd1935b60559ea7c6905b89cae" />
			</Helmet>
			<div className="scaVideo">
				<img  src="https://sca.lasun.com.cn//img/scabg1.png" className="scaVideoImg"/>
			</div>
			<div className="scaContent">
				<div className="scaTitle">
					信易盾 · SCA
				</div>
				<div className="scaTitle2">
					一站式软件成分分析 & 风险管控平台
				</div>
				<div className="scaTitleBtn" onClick={() => { goopen('https://manager.lasun.com.cn')}}>
				<Space className="mr5">
					<ApiOutlined />
				</Space>
					点击 开启安全检测
				</div>
				<div className="scaCon1">
					<div className="scaVideo2">
						<video id="myVideo4" width="100%" height="auto" muted loop >
							<source  src="https://sca.lasun.com.cn//img/scaV1.mp4" type="video/mp4" />
						</video>
					</div>
					<div className="scac1Title">
						你的代码真的足够安全吗 ?
					</div>
					<Row gutter={20}>
						<Col span={8}>
							<div className="sca1Card1">
								<div className="sca1ImgBg">
									<img className="sca1Imgs"  src="https://sca.lasun.com.cn//img/scarj.gif" />
								</div>
								<div className="sca1Text1">
									软件成分模糊，供应链风险高
								</div>
								<div className="sca1Text2">
									无法摸清开发过程中到底使用了哪些组件，难以维护供应链安全
								</div>
							</div>
						</Col>
						<Col span={8}>
							<div className="sca1Card1">
							<div className="sca1ImgBg">
								<img className="sca1Imgs"  src="https://sca.lasun.com.cn//img/scald.gif" />
							</div>
								<div className="sca1Text1">
									漏洞排查难度大，代码风险高
								</div>
								<div className="sca1Text2">
									组件之间的依赖关系纵深复杂，隐藏的漏洞难以识别，危害代码安全
								</div>
							</div>
						</Col>
						<Col span={8}>
							<div className="sca1Card1">
							<div className="sca1ImgBg">
								<img className="sca1Imgs"  src="https://sca.lasun.com.cn//img/scaxkz.gif" />
							</div>
								<div className="sca1Text1">
									许可证条款复杂，法律风险高
								</div>
								<div className="sca1Text2">
									许可证条款复杂、版本多样，互不兼容，威胁商业利益
								</div>
							</div>
						</Col>
					</Row>
					
				</div>
				{
					// 你的代码真的足够安全吗移动端
				}
				<div className="scaCon1Phone">
					<div className="scaVideo2">
						<video id="myVideo5" width="100%" height="auto" autoPlay muted loop >
							<source  src="https://sca.lasun.com.cn//img/scaV1.mp4" type="video/mp4" />
						</video>
					</div>
					<div className="scac1Title">
						你的代码真的足够安全吗 ?
					</div>
					<div className="sca1Card1">
						<div className="sca1ImgBg">
							<img className="sca1Imgs"  src="https://sca.lasun.com.cn//img/scarj.gif" />
						</div>
						<div className="sca1Text1">
							软件成分模糊，供应链风险高
						</div>
						<div className="sca1Text2">
							无法摸清开发过程中到底使用了哪些组件，难以维护供应链安全
						</div>
					</div>
					<div className="sca1Card1">
					<div className="sca1ImgBg">
						<img className="sca1Imgs"  src="https://sca.lasun.com.cn//img/scald.gif" />
					</div>
						<div className="sca1Text1">
							漏洞排查难度大，代码风险高
						</div>
						<div className="sca1Text2">
							组件之间的依赖关系纵深复杂，隐藏的漏洞难以识别，危害代码安全
						</div>
					</div>
					<div className="sca1Card1">
					<div className="sca1ImgBg">
						<img className="sca1Imgs"  src="https://sca.lasun.com.cn//img/scaxkz.gif" />
					</div>
						<div className="sca1Text1">
							许可证条款复杂，法律风险高
						</div>
						<div className="sca1Text2">
							许可证条款复杂、版本多样，互不兼容，威胁商业利益
						</div>
					</div>
				</div>
				<div className="scaCon2">
					<div className="sca2Card1">
						<Row gutter={20}>
							<Col span={12}>
								<div className="sca2cImgBg">
									<img  className="sca2cImg"  src="https://sca.lasun.com.cn//img/scaproduct1.png"/>
								</div>
							</Col>
							<Col span={12}>
								<div className="sca2cView">
									<div className="sca2cText1">
										软件物料清单（SBOM）
									</div>
									<div className="sca2cText2">
										全面展示软件中各成分构成及详细信息，深入分析组件间的依赖关系，提供详细的软件物料清单，帮助用户快速洞察供应链风险问题。
									</div>
								</div>
							</Col>
						</Row>
					</div>
					<div className="sca2Card1">
						<Row gutter={20}>
							<Col span={12}>
								<div className="sca2cView">
									<div className="sca2cText1">
										风险检测
									</div>
									<div className="sca2cText2">
										层层透视，深入分析组件之间的依赖关系，识别附着在每一层关系上的漏洞，为开发者提供清晰详尽的检测报告，帮助开发者全面掌握项目风险概况。
									</div>
								</div>
							</Col>
							<Col span={12}>
								<div className="sca2cImgBg">
									<img  className="sca2cImg"  src="https://sca.lasun.com.cn//img/scaproduct2.png"/>
								</div>
							</Col>
						</Row>
					</div>
					<div className="sca2Card1">
						<Row gutter={20}>
							<Col span={12}>
								<div className="sca2cImgBg">
									<img  className="sca2cImg"  src="https://sca.lasun.com.cn//img/scaproduct3.png"/>
								</div>
							</Col>
							<Col span={12}>
								<div className="sca2cView">
									<div className="sca2cText1">
										合规使用
									</div>
									<div className="sca2cText2">
										针对许可证条款进行专业翻译及解读，提炼关键性条款，总结合规风险点，帮助开发者正确规避合规风险
									</div>
								</div>
								
							</Col>
						</Row>
					</div>
					
				</div>
				{
					// 移动端
				}
				<div className="scaCon2Phone">
					<div className="sca2cView">
						<div className="sca2cText1">
							软件物料清单（SBOM）
						</div>
						<div className="sca2cText2">
							全面展示软件中各成分构成及详细信息，深入分析组件间的依赖关系，提供详细的软件物料清单，帮助用户快速洞察供应链风险问题。
						</div>
					</div>
					<div className="sca2cView">
						<div className="sca2cText1">
							风险检测
						</div>
						<div className="sca2cText2">
							层层透视，深入分析组件之间的依赖关系，识别附着在每一层关系上的漏洞，为开发者提供清晰详尽的检测报告，帮助开发者全面掌握项目风险概况。
						</div>
					</div>
					<div className="sca2cView">
						<div className="sca2cText1">
							合规使用
						</div>
						<div className="sca2cText2">
							针对许可证条款进行专业翻译及解读，提炼关键性条款，总结合规风险点，帮助开发者正确规避合规风险
						</div>
					</div>
				</div>
				
				<div className="scaCon3">
					<Divider plain style={{background:'rgba(164, 205, 255, 0.3)'}}>
					</Divider>
					<div className="scaCardCon">
						<img class="scaCardImg" src="https://sca.lasun.com.cn//img/scathree.png"
						alt="信易盾-开源软件管理平台"/>
					</div>
					<div className="scac3Title">
						产品优势
					</div>
					<div className="sca3Div1">
						<div className="sca3Card">
						</div>
						<div className="sca3cTitle">
							风险探测
						</div>
						<div className="sca3cImg">
							<img class="scaImgS" src="https://sca.lasun.com.cn//img/scafx.gif" alt="信易盾-开源软件管理平台"/>
						</div>
						<div className="sca3cText1">
							强大的漏洞库&深度的风险检测，为客户识别组件中的每一个漏洞
						</div>
					</div>
					<div className="sca3Div2">
						<div className="sca3Three">
							<div className="sca3Card2">
							</div>
							<div className="sca3D2s">
								<div className="sca3cTitle">
									风险感知
								</div>
								<div className="sca3cImg">
									<img class="scaImgS" src="https://sca.lasun.com.cn//img/scagz.gif" alt="信易盾-开源软件管理平台"/>
								</div>
								<div className="sca3cText1">
									定期数据更新&自动化风险监测，实现风险管理自动化&智能化
								</div>
							</div>
						</div>
						<div className="sca3Three">
							<div className="sca3Card3">
							</div>
							<div className="sca3D2c">
								<div className="sca3cTitle2">
									风险告警
								</div>
								<div className="sca3cImg2">
									<img class="scaImgS" src="https://sca.lasun.com.cn//img/scagj.gif" alt="信易盾-开源软件管理平台"/>
								</div>
								<div className="sca3cText2">
									第一时间发现风险&多种通知方式预警，避免风险信息滞后
								</div>
							</div>
						</div>
						<div className="sca3Three">
							<div className="sca3Card4">	
							</div>
							<div className="sca3D2r">
								<div className="sca3cTitle">
									风险修复
								</div>
								<div className="sca3cImg">
									<img class="scaImgS" src="https://sca.lasun.com.cn//img/scaxf.gif" alt="信易盾-开源软件管理平台"/>
								</div>
								<div className="sca3cText1">
									漏洞信息分析&许可证条款解读，提供风险解决方案
								</div>
							</div>
							
						</div>
						
					</div>
					
				</div>
				{
					//移动端
				}
				<div className="scaCon3Phone">
					<div className="scac3Title">
						产品优势
					</div>
					
					<div className="sca3Div1">
						<div className="sca3cTitle">
							风险探测
						</div>
						<div className="sca3cImg">
							<img class="scaImgS" src="https://sca.lasun.com.cn//img/scafx.gif" alt="信易盾-开源软件管理平台"/>
						</div>
						<div className="sca3cText1">
							强大的漏洞库&深度的风险检测，为客户识别组件中的每一个漏洞
						</div>
					</div>
					<div className="sca3Div1">
						<div className="sca3cTitle">
							风险感知
						</div>
						<div className="sca3cImg">
							<img class="scaImgS" src="https://sca.lasun.com.cn//img/scagz.gif" alt="信易盾-开源软件管理平台"/>
						</div>
						<div className="sca3cText1">
							定期数据更新&自动化风险监测，实现风险管理自动化&智能化
						</div>
					</div>
					<div className="sca3Div1">
						<div className="sca3cTitle">
							风险告警
						</div>
						<div className="sca3cImg">
							<img class="scaImgS" src="https://sca.lasun.com.cn//img/scagj.gif" alt="信易盾-开源软件管理平台"/>
						</div>
						<div className="sca3cText1">
							第一时间发现风险&多种通知方式预警，避免风险信息滞后
						</div>
					</div>
					<div className="sca3Div1">
						<div className="sca3cTitle">
							风险修复
						</div>
						<div className="sca3cImg">
							<img class="scaImgS" src="https://sca.lasun.com.cn//img/scaxf.gif" alt="信易盾-开源软件管理平台"/>
						</div>
						<div className="sca3cText1">
							漏洞信息分析&许可证条款解读，提供风险解决方案
						</div>
					</div>
				</div>
				<div className="scaCon4">
					<Divider plain style={{background:'rgba(164, 205, 255, 0.3)'}}>
					</Divider>
					<div className="scac4Title">
						产品能力
					</div>
					<div className="sca4ConDiv">
						<div className="sca4ConFlex">
							<div className="sca4Left">
								<div className="sca4Table">
									<div className="sca4T1">
										安全能力
									</div>
									<div className="sca4T2">
										<img class="sca4Image"  src="https://sca.lasun.com.cn//img/scanvd.png"
										alt="信易盾-开源软件管理平台"/>
										<img class="sca4Image"  src="https://sca.lasun.com.cn//img/scacnnvd.png"
										alt="信易盾-开源软件管理平台"/>
										<img class="sca4Image"  src="https://sca.lasun.com.cn//img/scacnvd.png"
										alt="信易盾-开源软件管理平台"/>
										<img class="sca4Image"  src="https://sca.lasun.com.cn//img/scalasun.png"
										alt="信易盾-开源软件管理平台"/>
									</div>
								</div>
								<div className="sca4Table">
									<div className="sca4T1">
										组件识别
									</div>
									<div className="sca4T2">
										<div class="sca4ImageText" >
											32万+漏洞数据
										</div>
										<div class="sca4ImageText" >
											400+许可证信息
										</div>
										<div class="sca4ImageText" >
											1亿+组件数据
										</div>
										<div class="sca4ImageText" >
											10种包管理工具
										</div>
									</div>
								</div>
								<div className="sca4Table">
									<div className="sca4T1">
										开发语言
									</div>
									<div className="sca4T2">
										<img class="sca4Image"  src="https://sca.lasun.com.cn//img/scajava.png"
										alt="信易盾-开源软件管理平台"/>
										<img class="sca4Image"  src="https://sca.lasun.com.cn//img/scapython.png"
										alt="信易盾-开源软件管理平台"/>
										<img class="sca4Image"  src="https://sca.lasun.com.cn//img/scarust.png"
										alt="信易盾-开源软件管理平台"/>
										<img class="sca4Image"  src="https://sca.lasun.com.cn//img/scajs.png"
										alt="信易盾-开源软件管理平台"/>
										<img class="sca4Image"  src="https://sca.lasun.com.cn//img/scaruby.png"
										alt="信易盾-开源软件管理平台"/>
										<img class="sca4Image"  src="https://sca.lasun.com.cn//img/scago.png"
										alt="信易盾-开源软件管理平台"/>
										<img class="sca4Image"  src="https://sca.lasun.com.cn//img/scaerlang.png"
										alt="信易盾-开源软件管理平台"/>
										<img class="sca4Image"  src="https://sca.lasun.com.cn//img/scaphp.png"
										alt="信易盾-开源软件管理平台"/>
									</div>
								</div>
								<div className="sca4TableEnd">
									<div className="sca4T1">
										开发集成
									</div>
									<div className="sca4T2">
										<img class="sca4Image"  src="https://sca.lasun.com.cn//img/scagitlab.png"
										alt="信易盾-开源软件管理平台"/>
										<img class="sca4Image"  src="https://sca.lasun.com.cn//img/scajenkins.png"
										alt="信易盾-开源软件管理平台"/>
										<img class="sca4Image"  src="https://sca.lasun.com.cn//img/scaidea.png"
										alt="信易盾-开源软件管理平台"/>
										<img class="sca4Image"  src="https://sca.lasun.com.cn//img/scaeclipse.png"
										alt="信易盾-开源软件管理平台"/>
										<img class="sca4Image"  src="https://sca.lasun.com.cn//img/scavs.png"
										alt="信易盾-开源软件管理平台"/>
										<img class="sca4Image"  src="https://sca.lasun.com.cn//img/scasvn.png"
										alt="信易盾-开源软件管理平台"/>
									</div>
								</div>
							</div>
							<div className="sca4Right">
								<div className="sca4TableLabel">
									<div className="sca4TableItem">
										languages
									</div>
									<div className="sca4TableItem">
										package Manager
									</div>
									<div className="sca4TableItem">
										Required Files
									</div>
								</div>
								<div className="sca4TabCh">
									<div className="sca4TabChItem">
										Java
									</div>
									<div className="sca4TabChItem">
										mavengradle
									</div>
									<div className="sca4TabChItem">
										<div className="sca4TabChLg">
											pom.xml
										</div>
										<div className="sca4TabChLg">
											gradle.kts
										</div>
									</div>
								</div>
								<div className="sca4TabCh">
									<div className="sca4TabChItem">
										JavaScript
									</div>
									<div className="sca4TabChItem">
										Npm
									</div>
									<div className="sca4TabChItem">
										<div className="sca4TabChLg">
											package-lock.json
										</div>
										<div className="sca4TabChLg">
											package.json
										</div>
									</div>
								</div>
								<div className="sca4TabCh">
									<div className="sca4TabChItem">
										PHP
									</div>
									<div className="sca4TabChItem">
										Composer
									</div>
									<div className="sca4TabChItem">
										<div className="sca4TabChLg">
											compiser.json
										</div>
										<div className="sca4TabChLg">
											composer.lock
										</div>
									</div>
								</div>
								<div className="sca4TabCh">
									<div className="sca4TabChItem">
										Ruby
									</div>
									<div className="sca4TabChItem">
										gem
									</div>
									<div className="sca4TabChItem">
										<div className="sca4TabChLg">
											gemfie.ock
										</div>
									</div>
								</div>
								<div className="sca4TabCh">
									<div className="sca4TabChItem">
										Golang
									</div>
									<div className="sca4TabChItem">
										gomod
									</div>
									<div className="sca4TabChItem">
										<div className="sca4TabChLg">
											go.mod
										</div>
										<div className="sca4TabChLg">
											go.sum
										</div>
									</div>
								</div>
								<div className="sca4TabCh">
									<div className="sca4TabChItem">
										Rust
									</div>
									<div className="sca4TabChItem">
										Cargo
									</div>
									<div className="sca4TabChItem">
										<div className="sca4TabChLg">
											Carg.lock
										</div>
									</div>
								</div>
								<div className="sca4TabCh">
									<div className="sca4TabChItem">
										Erlang
									</div>
									<div className="sca4TabChItem">
										Rebar
									</div>
									<div className="sca4TabChItem">
										<div className="sca4TabChLg">
											rebar.ock
										</div>
									</div>
								</div>
								<div className="sca4TabCh">
									<div className="sca4TabChItem">
										Python
									</div>
									<div className="sca4TabChItem">
										pipgradle
									</div>
									<div className="sca4TabChItem">
										<div className="sca4TabChLg">
											Pipfile.lockootup.Py
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	);
}
export default Sca;