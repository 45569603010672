import React, { useState,useEffect } from "react";
import { Input, Space, Menu, theme, Row, Col, Divider, Tooltip, Card, Image, Modal } from "antd";
import { DownloadOutlined, GithubOutlined, GitlabOutlined, WechatOutlined, WeiboOutlined, SafetyOutlined, ZhihuOutlined, DingtalkOutlined, EllipsisOutlined, RobotOutlined, ApiOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from "react-router-dom";
import { Stage, Layer, Arrow } from 'react-konva';
import "./index.css";
import { Helmet } from 'react-helmet';

function Home() {
	var hm = document.createElement("script");
	hm.src = "https://hm.baidu.com/hm.js?71b99c9d8eee3ed8bc4102a37ee8d3b5";
	var sr = document.getElementsByTagName("script")[0]; 
	sr.parentNode.insertBefore(hm, sr);
	
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const download = () => {
    var platform = navigator.platform;
    var fileUrl;
    if (platform === "Win32") {
      fileUrl = 'https://manager.lasun.com.cn/lasun/easyw-saas/installer/win64/信易盾安全助手-latest-installer.exe';
      var link = document.createElement('a');
      link.href = fileUrl;
      link.click();
    } else if (platform.includes("Mac")) {
      fileUrl = 'https://manager.lasun.com.cn/lasun/easyw-saas/installer/mac/信易盾安全助手.dmg';
      var link = document.createElement('a');
      link.href = fileUrl;
      link.click();
    } else if (platform.includes("Linux")) {
      alert('请使用PC端下载');
    } else {
      alert('请使用PC端下载');
    }
  };

  const goopen = (url) => {
    window.open(url);
  }

  const goNav = (path) => {
    window.location.href = path;
    window.scrollTo(0, 0);
  }
	
	function isMobileDevice() {
	  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	}
	useEffect(() => {
	  const myVideo = document.getElementById('myVideo');
	  const myVideo2 = document.getElementById('myVideo2');
	  if(isMobileDevice()){
		
	  }else{
		  if (myVideo) {
		  	myVideo.play();
		  }
		  if (myVideo2) {
		  	myVideo2.play();
		  }
	  }
	  
	}, []);


  return (
	<div className="homeView">
		<Helmet>
			<title>信易盾-开发安全全栈解决方案</title>
			<meta name="description" content="信易盾是一款Sca工具，包括信易盾客户端、信易盾Saas云服务、信易盾私有化部署和企业定制化附加服务"/>
			<meta name="keywords" content="安全左移, 研发安全, 开源安全, 漏洞扫描, DevSecOps, 敏捷安全, 软件成分分析sca, 
			银行sca, 保险sca, 证券sca, 开源治理, 金融sca" />
			<meta name="360-site-verification" content="dbbd44dd1935b60559ea7c6905b89cae" />
		</Helmet>
		<div className="homeContent">
			<div className="homeVideo">
				<div className="homeRight">
					<video id="myVideo" width="1152px" height="648px" muted loop >
						<source  src="https://sca.lasun.com.cn//img/homeV1.mp4" type="video/mp4" />
					</video>
				</div>
			</div>
			<div className="homeTitle">
				软件全生命周期开源治理解决方案
			</div>
			<div className="homeTitle2">
				<Row gutter={20}>
					<Col span={8}>
						<div className="ht2Btn">
							供应链安全
						</div>
					</Col>
					<Col span={8}>
						<div className="ht2Btn">
							漏洞安全
						</div>
					</Col>
					<Col span={8}>
						<div className="ht2Btn">
							许可证合规
						</div>
					</Col>
				</Row>
			</div>
			<div className="homeTitle3">
				<Row gutter={20}>
					<Col span={12}>
						<div className="ht3Btn" onClick={download}>
							<Space className="mr5">
								<DownloadOutlined />
							</Space>
							下载 信易盾安全助手
						</div>
					</Col>
					<Col span={12}>
						<div className="ht3Btn" onClick={() => { goopen('https://manager.lasun.com.cn')}}>
							<Space className="mr5">
								<ApiOutlined />
							</Space>
							点击 开启安全检测
						</div>
					</Col>
				</Row>
			</div>
			<div className="homeTitle4">
				<div className="homeT4dis">
					<div className="homeT4item">
						<Tooltip title="开源上线中 敬请期待">
							<GithubOutlined className="ht4Btn"/>
						</Tooltip>
					</div>
					<div className="homeT4item">
						<Tooltip title="开源上线中 敬请期待" onClick={() => { goopen('https://gitee.com/lasun_opensource')}}>
							<img src="https://sca.lasun.com.cn//img/gitee.svg" className="ht4Btn"/>
						</Tooltip>
					</div>
					<div className="homeT4item">
						<div onClick={showModal}>
							<WechatOutlined className="ht4Btn"/>
						</div>
					</div>
					<div className="homeT4item">
						<Tooltip title="去知乎看看">
							<ZhihuOutlined className="ht4Btn" onClick={() => { goopen('https://www.zhihu.com/org/xin-yi-dun')}}/>
						</Tooltip>
					</div>
					<div className="homeT4item">
						<Tooltip title="去百度看看" onClick={() => { goopen('https://author.baidu.com/home?context=%7B%22uk%22%3A%22Gjch5y8WlHr23sMTyywn_Q%22%7D')}}>
							<img src="https://sca.lasun.com.cn//img/baidu.svg" className="ht4Btn"/>
						</Tooltip>
					</div>
				</div>
			</div>
			{
				// 智能化安全解决方案
			}
			<div className="homeView1">
				<div className="hv1Bg">
					<div className="hv1dev">
						<video id="myVideo2" width="100%" height="auto" muted loop >
							<source src="https://sca.lasun.com.cn//img/homeV2.mp4" type="video/mp4" />
						</video>
					</div>
					<div className="hv1Title">
						DevSecOps 智能化安全解决方案
					</div>
					<Row>
						<Col span={8}>
							<div className="hv1Card1">
								<div className="hv1CardTitle">
									信易盾·SAAS
								</div>
								<div className="hv1CardText1">
									<SafetyOutlined  className="hv1Icon"/>本地化服务
								</div>
								<div className="hv1CardText2">
									<SafetyOutlined  className="hv1Icon"/>便捷检测
								</div>
								<div className="hv1CardText3">
									<SafetyOutlined  className="hv1Icon"/>快速输出SBOM
								</div>
								<div className="hv1CardBtn" onClick={download}>
									<Space className="mr5 hv1BtnIcon">
										<DownloadOutlined />
									</Space>下载信易盾安全助手
								</div>
							</div>
							<div className="hv1Card2">
								<div className="hv1CardTitle">
									信易盾·SAST
								</div>
								<div className="hv1CardText1">
									<SafetyOutlined  className="hv1Icon"/>编码安全检测
								</div>
								<div className="hv1CardText2">
									<SafetyOutlined  className="hv1Icon"/>提升开发效率
								</div>
								<div className="hv1CardText3">
									<SafetyOutlined  className="hv1Icon"/>掌握开发安全
								</div>
								<div className="hv1CardBtn" onClick={() => { goNav('/sast/')}} >
									了解更多<EllipsisOutlined className="hv1BtnIcon"/>
								</div>
							</div>
						</Col>
						<Col span={8}>
							<div className="hv1Round">
								<div className="hv1rView">
									<div className="hv1rSaas">
										信易盾-SAAS
									</div>
									<div className="hv1Arrow1">
										<Stage width={100} height={60}>
											<Layer>
												<Arrow
													points={[100,30,50,25,0,15]}
													stroke="#fa762b"
													fill="#fa762b"
													dash={[5, 5]} // 可选：添加虚线效果
													tension={0.3} // 可选：设置线条的弯曲度
													strokeWidth={2}
												/>
											</Layer>
										</Stage>
									</div>
									<div className="hv1rSast">
										信易盾-SAST
									</div>
									<div className="hv1Arrow2">
										<Stage width={100} height={60}>
											<Layer>
												<Arrow
													points={[100,30,50,35,0,45]}
													stroke="#149a43"
													fill="#149a43"
													dash={[5, 5]} // 可选：添加虚线效果
													tension={0.3} // 可选：设置线条的弯曲度
													strokeWidth={2}
												/>
											</Layer>
										</Stage>
									</div>
									<div className="hv1rSca">
										信易盾-SCA
									</div>
									<div className="hv1Arrow3">
										<Stage width={100} height={60}>
											<Layer>
												<Arrow
													points={[0,30,50,25,100,15]}
													stroke="#aa55ff"
													fill="#aa55ff"
													dash={[5, 5]} // 可选：添加虚线效果
													tension={0.3} // 可选：设置线条的弯曲度
													strokeWidth={2}
												/>
											</Layer>
										</Stage>
									</div>
									<div className="hv1rIast">
										信易盾-IAST
									</div>
									<div className="hv1Arrow4">
										<Stage width={100} height={60}>
											<Layer>
												<Arrow
													points={[0,30,50,35,100,45]}
													stroke="#2f94ff"
													fill="#2f94ff"
													dash={[5, 5]} // 可选：添加虚线效果
													tension={0.3} // 可选：设置线条的弯曲度
													strokeWidth={2}
												/>
											</Layer>
										</Stage>
									</div>
									<div className="hv1rTitleR">
										<div className="hv1rTitle1">信易盾</div>
									</div>
									
								</div>
							</div>
						</Col>
						<Col span={8}>
							<div className="hv1Card1">
								<div className="hv1CardTitle">
									信易盾·SCA
								</div>
								<div className="hv1CardText1">
									<SafetyOutlined className="hv1Icon"/>第三方组件分析
								</div>
								<div className="hv1CardText2">
									<SafetyOutlined  className="hv1Icon"/>漏洞识别
								</div>
								<div className="hv1CardText3">
									<SafetyOutlined  className="hv1Icon"/>许可证合规检测
								</div>
								<div className="hv1CardBtn" onClick={() => {goNav('/sca/')}}>
									了解更多<EllipsisOutlined className="hv1BtnIcon"/>
								</div>
							</div>
							<div className="hv1Card2">
								<div className="hv1CardTitle">
									信易盾·IAST
								</div>
								<div className="hv1CardText1">
									<SafetyOutlined  className="hv1Icon"/>检测全覆盖
								</div>
								<div className="hv1CardText2">
									<SafetyOutlined  className="hv1Icon"/>业务零打扰
								</div>
								<div className="hv1CardText3">
									<SafetyOutlined  className="hv1Icon"/>大规模自动化部署
								</div>
								<div className="hv1CardBtn" onClick={() => { goNav('/iast/')}}>
									了解更多<EllipsisOutlined className="hv1BtnIcon"/>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</div>
			
			{
				// 移动端
			}
			<div className="homeView1Phone">
				<div className="hv1Title">
					DevSecOps 智能化安全解决方案
				</div>
				<div className="hv1Card1">
					<div className="hv1CardTitle">
						信易盾·SAAS
					</div>
					<div className="hv1CardText1">
						<SafetyOutlined  className="hv1Icon"/>本地化服务
					</div>
					<div className="hv1CardText2">
						<SafetyOutlined  className="hv1Icon"/>便捷检测
					</div>
					<div className="hv1CardText3">
						<SafetyOutlined  className="hv1Icon"/>快速输出SBOM
					</div>
					<div className="hv1CardBtn" onClick={download}>
						<Space className="mr5 hv1BtnIcon">
							<DownloadOutlined />
						</Space>下载信易盾安全助手
					</div>
				</div>
				<div className="hv1Card2">
					<div className="hv1CardTitle">
						信易盾·SAST
					</div>
					<div className="hv1CardText1">
						<SafetyOutlined  className="hv1Icon"/>编码安全检测
					</div>
					<div className="hv1CardText2">
						<SafetyOutlined  className="hv1Icon"/>提升开发效率
					</div>
					<div className="hv1CardText3">
						<SafetyOutlined  className="hv1Icon"/>掌握开发安全
					</div>
					<div className="hv1CardBtn" onClick={() => { goNav('/sast/')}} >
						了解更多<EllipsisOutlined className="hv1BtnIcon"/>
					</div>
				</div>
				<div className="hv1Card1">
					<div className="hv1CardTitle">
						信易盾·SCA
					</div>
					<div className="hv1CardText1">
						<SafetyOutlined className="hv1Icon"/>第三方组件分析
					</div>
					<div className="hv1CardText2">
						<SafetyOutlined  className="hv1Icon"/>漏洞识别
					</div>
					<div className="hv1CardText3">
						<SafetyOutlined  className="hv1Icon"/>许可证合规检测
					</div>
					<div className="hv1CardBtn" onClick={() => {goNav('/sca/')}}>
						了解更多<EllipsisOutlined className="hv1BtnIcon"/>
					</div>
				</div>
				<div className="hv1Card2">
					<div className="hv1CardTitle">
						信易盾·IAST
					</div>
					<div className="hv1CardText1">
						<SafetyOutlined  className="hv1Icon"/>检测全覆盖
					</div>
					<div className="hv1CardText2">
						<SafetyOutlined  className="hv1Icon"/>业务零打扰
					</div>
					<div className="hv1CardText3">
						<SafetyOutlined  className="hv1Icon"/>大规模自动化部署
					</div>
					<div className="hv1CardBtn" onClick={() => { goNav('/iast/')}}>
						了解更多<EllipsisOutlined className="hv1BtnIcon"/>
					</div>
				</div>
			</div>
			{
				// 我们的优势
			}
			<div className="homeView2">
				<Divider plain style={{background:'rgba(164, 205, 255, 0.5)'}}>
				</Divider>
				<div className="hv2Title">
					我们的优势
				</div>
				<Row gutter={20}>
					<Col span={8}>
						<div className="hv2Card">
							<div className="hv2CardDiv">
								<img class="hv2CardImg" src="https://sca.lasun.com.cn//img/homeyf.png"
								alt="信易盾-开源软件管理平台"/>
							</div>
							<div className="hv2cTitle">
								国产研发 自主可控
							</div>
							<div className="hv2cText1">
								domestically developed with independent controllability
							</div>
							<div className="hv2cText2wy">
								<RobotOutlined  className="hv2Icon"/>核心代码均为自主研发
							</div>
							<div className="hv2cText3">
								<RobotOutlined  className="hv2Icon"/>可在信创环境下进行部署
							</div>
						</div>
					</Col>
					<Col span={8}>
						<div className="hv2Card">
							<div className="hv2CardDiv">
								<img class="hv2CardImg"  src="https://sca.lasun.com.cn//img/homeaq.png"
								alt="信易盾-开源软件管理平台"/>
							</div>
							<div className="hv2cTitle">
								尊重守护您的信息安全
							</div>
							<div className="hv2cText1">
								Respect and safeguard your information security.
							</div>
							<div className="hv2cText2">
								<RobotOutlined  className="hv2Icon"/>根据权限机制进行项目隔离
							</div>
							<div className="hv2cText3">
								<RobotOutlined  className="hv2Icon"/>不保留用户项目代码
							</div>
							<div className="hv2cText4">
								<RobotOutlined  className="hv2Icon"/>支持私有化&离线部署
							</div>
						</div>
					</Col>
					<Col span={8}>
						<div className="hv2Card">
							<div className="hv2CardDiv">
								<img class="hv2CardImg" src="https://sca.lasun.com.cn//img/homezdh.png"
								alt="信易盾-开源软件管理平台"/>
							</div>
							<div className="hv2cTitle">
								实现自动化 智能化防护
							</div>
							<div className="hv2cText1">
								Achieve automated and intelligent protection.
							</div>
							<div className="hv2cText2">
								<RobotOutlined  className="hv2Icon"/>支持定期检测已有项目风险
							</div>
							<div className="hv2cText3">
								<RobotOutlined  className="hv2Icon"/>24H监测新风险并自动更新
							</div>
							<div className="hv2cText4">
								<RobotOutlined  className="hv2Icon"/>提供最新风险告警
							</div>
						</div>
					</Col>
				</Row>
			</div>
			{
				// 我们的优势移动端
			}
			<div className="homeView2Phone">
				<Divider plain style={{background:'rgba(164, 205, 255, 0.5)'}}>
				</Divider>
				<div className="hv2Title">
					我们的优势
				</div>
				<div className="hv2Card">
					<div className="hv2CardDiv">
						<img class="hv2CardImg" src="https://sca.lasun.com.cn//img/homeyf.png"
						alt="信易盾-开源软件管理平台"/>
					</div>
					<div className="hv2cTitle">
						国产研发 自主可控
					</div>
					<div className="hv2cText1">
						domestically developed with independent controllability
					</div>
					<div className="hv2cText2wy">
						<RobotOutlined  className="hv2Icon"/>核心代码均为自主研发
					</div>
					<div className="hv2cText3">
						<RobotOutlined  className="hv2Icon"/>可在信创环境下进行部署
					</div>
				</div>
				<div className="hv2Card">
					<div className="hv2CardDiv">
						<img class="hv2CardImg"  src="https://sca.lasun.com.cn//img/homeaq.png"
						alt="信易盾-开源软件管理平台"/>
					</div>
					<div className="hv2cTitle">
						尊重守护您的信息安全
					</div>
					<div className="hv2cText1">
						Respect and safeguard your information security.
					</div>
					<div className="hv2cText2">
						<RobotOutlined  className="hv2Icon"/>根据权限机制进行项目隔离
					</div>
					<div className="hv2cText3">
						<RobotOutlined  className="hv2Icon"/>不保留用户项目代码
					</div>
					<div className="hv2cText4">
						<RobotOutlined  className="hv2Icon"/>支持私有化&离线部署
					</div>
				</div>
				<div className="hv2Card">
					<div className="hv2CardDiv">
						<img class="hv2CardImg" src="https://sca.lasun.com.cn//img/homezdh.png"
						alt="信易盾-开源软件管理平台"/>
					</div>
					<div className="hv2cTitle">
						实现自动化 智能化防护
					</div>
					<div className="hv2cText1">
						Achieve automated and intelligent protection.
					</div>
					<div className="hv2cText2">
						<RobotOutlined  className="hv2Icon"/>支持定期检测已有项目风险
					</div>
					<div className="hv2cText3">
						<RobotOutlined  className="hv2Icon"/>24H监测新风险并自动更新
					</div>
					<div className="hv2cText4">
						<RobotOutlined  className="hv2Icon"/>提供最新风险告警
					</div>
				</div>
			</div>
			{
				// 把安全风险识别交给我们
			}
			<div className="homeView3">
				<Divider plain style={{background:'rgba(164, 205, 255, 0.5)'}}>
				</Divider>
				<div className="hv3Title">
					把安全风险识别交给我们
				</div>
				<div className="hv3Div">
					<div className="hv3CardCon">
						<img class="hv3CardImg" src="https://sca.lasun.com.cn//img/hand.jpg"
						alt="信易盾-开源软件管理平台"/>
					</div>
					<Row>
						<Col span={12}>
							<div className="hv3Card">
								<div className="hv3CardTitle">理清开发项目中的开源组件成分</div>
								<Divider plain style={{background:'rgba(164, 205, 255, 0.5)'}}>
								</Divider>
								<div className="hv3CardEng">Clarify the components of open source components in development projectst</div>
							</div>
							<div className="hv3Card">
								<div className="hv3CardTitle">梳理开源组件间的依赖关系</div>
								<Divider plain style={{background:'rgba(164, 205, 255, 0.5)'}}>
								</Divider>
								<div className="hv3CardEng">Sort out the dependencies between open source components</div>
							</div>
							<div className="hv3Card">
								<div className="hv3CardTitle">识别附着在组件上的安全漏洞</div>
								<Divider plain style={{background:'rgba(164, 205, 255, 0.5)'}}>
								</Divider>
								<div className="hv3CardEng">Identify security vulnerabilities attached to components</div>
							</div>
						</Col>
						<Col span={12}>
							<div className="hv3Card">
								<div className="hv3CardTitle">识别组件许可证及潜在合规风险</div>
								<Divider plain style={{background:'rgba(164, 205, 255, 0.5)'}}>
								</Divider>
								<div className="hv3CardEng">Identify component licenses and potential compliance risks</div>
							</div>
							<div className="hv3Card">
								<div className="hv3CardTitle">分析风险问题，提供修复方案</div>
								<Divider plain style={{background:'rgba(164, 205, 255, 0.5)'}}>
								</Divider>
								<div className="hv3CardEng">Analyze risk problems and provide solutions</div>
							</div>
							<div className="hv3Card">
								<div className="hv3CardTitle">实现全面有效的开源组件安全治理</div>
								<Divider plain style={{background:'rgba(164, 205, 255, 0.5)'}}>
								</Divider>
								<div className="hv3CardEng">Implement comprehensive and effective open source component security governance</div>
							</div>
						</Col>
					</Row>
					
				</div>
			</div>
		</div>
		<Modal open={open} onCancel={handleCancel} footer={[]} width="500px">
			<div className="modelView">
				<div className="modelTitle">
					WeChat
				</div>
				<div className="modelTitle2">
					官方公众号
				</div>
				<div className="modelIcon3">
					<div className="modelImgV1">
						<img class="modelqrimgs" src="https://sca.lasun.com.cn//img/qrcode.png" />
						<div class="modelText">
							灵信互动
						</div>
					</div>
					<div className="modelImgV1">
						<img class="modelqrimgs" src="https://sca.lasun.com.cn//img/qrcode2.jpg" />
						<div class="modelText">
							产品服务
						</div>
					</div>
				</div>
			</div>
		</Modal>
	</div>
  );
}

export default Home;
