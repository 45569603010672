//默认state初始值为空，因为data是json形式，所以i为空对象
let initState = {};
export default function reducer(perState = initState, action) {
  let { type, data } = action;
  switch (type) {
    case "details":
      const data1 = { ...perState, ...data };
      console.log(data1);
      //data为新传入进来的item
      return { ...perState, ...data };
    default:
      return perState;
  }
}
