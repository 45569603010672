import React, { Component } from "react";
import store from "../../story/story";
import "./details.css";

export default class details extends Component {
  render() {
    const dataList = store.getState();
    console.log(dataList);
    return (
      <div className="page_contain">
        <p className="m-title">{dataList.title}</p>
        <div className="page_dic">
          <span className="author">{dataList.author}</span>
          <span className="like">关注</span>
          <span className="m-dic">IP属地：{dataList.details.ip}</span>
          <p>
            <span className="dic">关注 {dataList.like}</span>
            <span className="m-dic">{dataList.details.time}</span>
            <span className="m-dic">字数 {dataList.details.wordNum}</span>
            <span className="m-dic">阅读 {dataList.details.readNum}</span>
          </p>
        </div>
        <p className="qianyan">前言</p>
        {/* <p className="con">{dataList.con}</p> */}
        <div
          className="con"
          dangerouslySetInnerHTML={{ __html: dataList.con }}
        ></div>
      </div>
    );
  }
}
